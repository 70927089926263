import React from "react";
import type { NextPage } from "next";
import Seo from "@/components/Shared/Seo";
import { Error404 } from "@scanow/ui";

const Error404Page: NextPage = () => {
  const pageImage = "/images/404.png";
  return (
    <>
      <Seo
        pageTitle={"Page introuvable"}
        pageDescription={"La page demandée n'existe pas"}
        pageImage={pageImage}
      />
      <Error404 />
    </>
  );
};
export default Error404Page;
